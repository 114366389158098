<template>
  <l-map class="map" style="height: 580px" :center="[46,32]" :zoom="4" :minZoom="4" :maxZoom="4" :zoomControl="false">
    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
    <l-marker v-for="(value, key) in datamap" :key="key" :lat-lng="[value.lat, value.long]"></l-marker>
  </l-map>
</template>

<script lang="js">
  import "leaflet/dist/leaflet.css"
  import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
  export default {
    name: 'map_component',
    props: [],
    components:{
      LMap,
      LTileLayer,
      LMarker,
    },
    data() {
      return {
        datamap: [
          {
            'name': 'Spain',
            'lat': '40.440396193258586',
            'long': ' -3.7866217465756096',
          },
          {
            'name': 'Romania',
            'lat': '46.75152709949154',
            'long': '23.575748882836713',
          },
          {
            'name': 'Palma',
            'lat': '39.606645466130715',
            'long': '2.6696040554244416',
          },
          {
            'name': 'UK',
            'lat': '52.0297304711839',
            'long': '-2.1437724756152035',
          },
          {
            'name': 'India',
            'lat': '12.95638549090307',
            'long': '80.24765221995018',
          }
        ]
      }
    },
    computed: {

    }
  }
</script>

<style lang="scss">
  .leaflet-left{
    display: none;
  }
  .map {
    height: 580px !important;
  }

  @media screen and (max-width:700px) {
    .map {
      height: 250px !important;
    }
  }
</style>