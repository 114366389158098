<template lang="html">

  <section class="contact">
    <section class="head">
      <div class="container">
        <div class="container-form">
          <div class="form">
            <img src="/img/icons/icon-mail-black.svg" alt="Icon email" class="img-detail">
            <h1 class="title-medium">{{  $t('contact.email')  }}</h1>
            <p>{{  $t('contact.email_text')  }}</p>
            <template v-if="formSend == true">
              <p class="title-medium -blueC msg-success">{{$t('contact.mensajeEnviar')}}<br>
                {{$t('contact.mensaje2')}}</p>
            </template>
            <template v-else>
              <Form :validation-schema="schema" v-slot="{ errors }" @submit="onSubmit">
                <div class="column">
                  <Field name="name" class="input-styled keyword" :placeholder="$t('contact.name')"
                    :class="{ error: errors.name }" v-model="data.name" />
                  <span class="mss-error">{{  errors.name  }}</span>
                </div>
                <div class="column">
                  <Field name="email" class="input-styled email" :placeholder="$t('contact.mail')"
                    :class="{ error: errors.email }" v-model="data.email" />
                  <span class="mss-error">{{  errors.email  }}</span>
                </div>

                <div class="column">
                  <Field as="textarea" name="message" class="input-styled message" :placeholder="$t('contact.message')"
                    :class="{ error: errors.message }" v-model="data.message" />
                </div>
                <div class="checkbox-styled">
                  <Field id="acceptance_legal" name="acceptance_legal" type="checkbox" v-model="data.acceptance_legal"
                    :value="true" :unchecked-value="false" />
                  <label for="acceptance_legal">
                    {{  $t('contact.accept_policy')  }}
                    <router-link :to="{ name: 'privacy' }">{{  $t('contact.policy')  }}</router-link>
                  </label>
                  <span class="mss-error">{{  errors.acceptance_legal  }}</span>
                </div>
                <div class="w-100">
                  <button class="btn-blue " @click="recaptcha">{{  $t('contact.send')  }}</button>
                </div>
              </Form>
            </template>
          </div>
          <div>
            <router-link :to="{ name: 'demo' }">
              <div class="info-contact cliente demo">

                <h2>{{$t('contact.demo')}}</h2>
                <p><a><img src="/img/flecha-oscura.svg" alt="flecha oscura"></a></p>
              </div>
            </router-link>
            <a href="https://app.r-sami.com/" target="_blank">
              <div class="info-contact cliente contacta">

                <div>
                  <p class="texto-azul">{{$t('contact.cliente')}}</p>
                  <h2>{{$t('contact.soporte')}}</h2>
                </div>

                <a><img src="/img/flecha-blanca.svg" alt="flecha blanca"></a>
              </div>
            </a>

            <div class="info-contact">

              <img src="/img/icons/icon-phone-white.svg" alt="Icon email" class="img-detail">
              <h2 class="title-medium -whiteC">{{  $t('contact.phone')  }}</h2>
              <p class="-whiteC">{{  $t('contact.contact')  }}</p>
              <a href="tel:+34919498458 " class="icon-phone">+34 919 49 84 58</a>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="sucursals">
      <Waypoint class="container grid-4" effect="fadeInDown" @change="quitarEfecto()" id="animacion">

        <div class="grid sucursal-box">
          <p class="title">{{$t('contact.map.spain')}}</p>
          <p>Vía de las Dos Castillas 33
            28224 Pozuelo de Alarcón
            {{$t('contact.map.spain')}}
          </p>
          <hr>
          <p>Carrer Gremi des Fusters 33
            07009 Palma de Mallorca
            {{$t('contact.map.spain')}}
          </p>
        </div>
        <div class="grid sucursal-box">
          <p class="title">{{$t('contact.map.romania')}}</p>
          <p>106 Frunzisului Street, 400664 Cluj-Napoca, {{$t('contact.map.romania')}}
          </p>
        </div>
        <div class="grid sucursal-box">
          <p class="title">{{$t('contact.map.uk')}}</p>
          <p>13 Grace Gardens Cheltenham, Gloucestershire,
            GL51 6QE (Reino Unido)
          </p>
        </div>
        <div class="grid sucursal-box">
          <p class="title">{{$t('contact.map.india')}}</p>
          <p>No. 96,{{$t('contact.map.secondfloor')}}
            Perungudi, Chennai-600096,
            TN, INDIA
          </p>
        </div>
      </Waypoint>
    </section>
    <mapOpenLayer></mapOpenLayer>
  </section>

</template>

<script lang="js">
import axios from 'axios'
import { Waypoint } from 'vue-waypoint'
import {
  Form,
  Field
} from "vee-validate";
import mapOpenLayer from "@/components/map.vue";
export default {
  name: 'contact',
  props: [],
  components: {
    Field,
    Form,
    mapOpenLayer,
    Waypoint,
  },

  data() {
    const schema = {
      name: 'required',
      email: 'required|email',
      acceptance_legal: 'required'
    };
    return {
      formSend: false,
      i:0,
      schema,
      data: {
        name: '',
        email: '',
        message: '',
        acceptance_legal: ''
      }
    };
  },
  methods: {
    onSubmit() {
      return new Promise((resolve) => {
        axios.post('autocontact/default', this.data).then(response => {
          resolve(response);
          this.formSend = true
        })
      });
    },
    quitarEfecto(){
      this.i++;
      var animacion=document.getElementById('animacion');
      if(this.i==3){
        animacion.setAttribute('effect','');
      }
      
    }
  }
}


</script>

<style scoped lang="scss">
  .info-contact.cliente.contacta div+p{
    padding:40px
  }
  .texto-azul{
    font-size:22px;
    & +p{
      font-size: 32px;;
    }
  }

  .info-contact.cliente.demo p{
    padding:40px;
  }
  .info-contact.cliente.demo h2{
    padding:40px;
    font-weight: bold;
  }
  .info-contact.cliente.contacta div{
    padding:40px;
  }
  .contact .head .container{
    max-width:1242px;
  }
  @media screen and (max-width:900px){
    .contact{
      padding-top: 50px;
    }
    .info-contact.cliente.demo,
    .info-contact.cliente.contacta{
      display: flex;
    flex-direction: row;
    }
    .contact .sucursals {
     padding: 0px ; 
}
    .info-contact.cliente.demo p{
    padding:0px;
  }
  .info-contact.cliente.demo h2{
    padding:0px;
  }
  .info-contact.cliente.contacta div {
    padding: 0px;
    text-align: left;
}
.info-contact.cliente.contacta a{
    padding-right: 0px !important;
  
}
  }
</style>